import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Space, Modal, message, Input, Tag } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { userApi } from '../services/api';
import { formatDate, formatAmount, formatWalletAddress } from '../utils/format';

const { confirm } = Modal;
const { Search } = Input;

export function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [search, setSearch] = useState('');

  const fetchUsers = async (page = 1, pageSize = 10, searchText = '') => {
    try {
      setLoading(true);
      const response = await userApi.getUsers({
        page,
        pageSize,
        search: searchText
      });

      if (response.success) {
        const userData = Array.isArray(response.data) ? response.data : response.data.users || [];
        setUsers(userData);
        
        setPagination({
          current: page,
          pageSize: pageSize,
          total: Array.isArray(response.data) ? response.data.length : (response.data.total || userData.length)
        });
      }
    } catch (error) {
      console.error('获取用户列表失败:', error);
      message.error('获取用户列表失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(1, pagination.pageSize);
  }, []);

  const handleTableChange = (newPagination) => {
    fetchUsers(newPagination.current, newPagination.pageSize, search);
  };

  const handleSearch = (value) => {
    setSearch(value);
    fetchUsers(1, pagination.pageSize, value);
  };

  const handleDelete = (userId) => {
    confirm({
      title: '确认删除',
      icon: <ExclamationCircleOutlined />,
      content: '确定要删除这个用户吗？此操作不可恢复。',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        try {
          const response = await userApi.deleteUser(userId);
          if (response.success) {
            message.success('删除用户成功');
            fetchUsers(pagination.current, pagination.pageSize, search);
          }
        } catch (error) {
          console.error('删除用户失败:', error);
          message.error(error.message || '删除用户失败');
        }
      }
    });
  };

  const columns = [
    {
      title: '钱包地址',
      dataIndex: 'walletAddress',
      render: (text) => formatWalletAddress(text),
    },
    {
      title: '联创身份',
      dataIndex: 'isCoFounder',
      width: 100,
      render: (value) => (
        <Tag color={value ? 'gold' : 'default'}>
          {value ? '联创' : '普通'}
        </Tag>
      ),
    },
    {
      title: '邀请码',
      dataIndex: 'inviteCode',
    },
    {
      title: '推荐人',
      dataIndex: ['Referrer', 'walletAddress'],
      render: (text) => text ? formatWalletAddress(text) : '-',
    },
    {
      title: '有效邀请人数',
      dataIndex: 'validReferralsCount',
      sorter: (a, b) => a.validReferralsCount - b.validReferralsCount,
    },
    {
      title: '总投资额',
      dataIndex: 'totalInvest',
      render: (value) => formatAmount(value, 2),
      sorter: (a, b) => a.totalInvest - b.totalInvest,
    },
    {
      title: '总代币',
      dataIndex: 'totalTokens',
      render: (value) => formatAmount(value, 0),
    },
    {
      title: '已返还',
      dataIndex: 'totalReturned',
      render: (value) => formatAmount(value, 0),
    },
    {
      title: '待返还',
      dataIndex: 'totalRemaining',
      render: (value) => formatAmount(value, 0),
    },
    {
      title: '钱包余额',
      dataIndex: 'balance',
      render: (value) => formatAmount(value, 0),
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: '总奖励',
      dataIndex: 'totalReward',
      render: (value) => formatAmount(value, 2),
      sorter: (a, b) => a.totalReward - b.totalReward,
    },
    {
      title: '注册时间',
      dataIndex: 'createdAt',
      render: (text) => formatDate(text),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            danger
            size="small"
            onClick={() => handleDelete(record.id)}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card title="用户管理" className="h-full">
      <div className="mb-4 flex items-center justify-between">
        <Search
          placeholder="搜索钱包地址或邀请码"
          allowClear
          enterButton
          onSearch={handleSearch}
          style={{ width: 300 }}
        />
        <div className="text-gray-500 text-sm">
          共 {pagination.total} 个用户
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        pagination={pagination}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ x: 1200 }}
      />
    </Card>
  );
}