import dayjs from 'dayjs';

// 格式化日期时间
export const formatDate = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) return '-';
  return dayjs(date).format(format);
};

// 格式化金额
export const formatAmount = (value, decimals = 2) => {
  if (!value && value !== 0) return '-';
  return Number(value).toLocaleString('zh-CN', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

// 格式化数字（添加千位分隔符）
export const formatNumber = (number) => {
  if (number === undefined || number === null) return '-';
  return number.toLocaleString();
};

// 格式化钱包地址（显示前6位和后4位）
export const formatWalletAddress = (address) => {
  if (!address) return '-';
  if (address.length < 10) return address;
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}; 