import axios from 'axios';
import { message } from 'antd';

// 根据环境判断使用哪个 baseURL
const isDevelopment = process.env.NODE_ENV === 'development';
const baseURL = isDevelopment 
  ? 'https://admin.shrileywang.cc/api'  // 开发环境
  : 'https://admin.shrileywang.cc/api';  // 生产环境

const api = axios.create({
  baseURL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('admin_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // 根据环境设置不同的请求头
    if (!isDevelopment) {
      config.headers['Origin'] = 'https://admin.shrileywang.cc';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorMessage = error.response?.data?.message || error.message || '请求失败';
    message.error(errorMessage);

    if (error.response?.status === 401) {
      localStorage.removeItem('admin_token');
      window.location.href = '/login';
    }
    return Promise.reject(error.response?.data || error);
  }
);

// 订单相关接口
export const orderApi = {
  getOrders: (params) => api.get('/orders', { params }),
  getStats: () => api.get('/orders/stats'),
  deleteOrder: (id) => api.delete(`/orders/${id}`),
};

// 用户相关接口
export const userApi = {
  getUsers: (params) => api.get('/users', { params }),
  getStats: () => api.get('/users/stats'),
  deleteUser: (id) => api.delete(`/users/${id}`),
  login: (data) => api.post('/auth/login', data),
  changePassword: (data) => api.post('/auth/change-password', data),
};

// 返还记录相关接口
export const returnApi = {
  getReturns: (params) => api.get('/returns/records', { params }),
  getRecords: (params) => api.get('/returns/records', { params }),
  getStats: () => api.get('/returns/stats'),
  triggerReturn: () => api.get('/returns/trigger'),
};

// 返佣记录相关接口
export const commissionApi = {
  getRecords: (params) => api.get('/commissions/records', { params }),
  getStats: () => api.get('/commissions/stats'),
};