import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Space, message, Tag, Modal } from 'antd';
import { formatDate, formatAmount, formatWalletAddress } from '../utils/format';
import request from '../utils/request';

export function CoFounderRewards() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [triggerLoading, setTriggerLoading] = useState(false);

  // 获取联创返佣记录
  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const response = await request('/co-founder/rewards', {
        method: 'GET',
        params: {
          page: params.current || 1,
          limit: params.pageSize || 10
        }
      });

      if (response.success) {
        setData(response.rewards || []);
        setPagination({
          ...pagination,
          total: response.total,
          current: response.page
        });
      }
    } catch (error) {
      message.error('获取联创返佣记录失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      current: 1,
      pageSize: 10
    });
  }, []);

  // 处理表格变化
  const handleTableChange = (newPagination) => {
    fetchData({
      current: newPagination.current,
      pageSize: newPagination.pageSize
    });
  };

  // 触发联创返佣分配
  const handleTriggerReward = async () => {
    Modal.confirm({
      title: '确认触发联创返佣分配',
      content: '确定要执行今日的联创返佣分配吗？此操作不可撤销。',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        try {
          setTriggerLoading(true);
          const response = await request('/co-founder/trigger-reward', {
            method: 'POST'
          });

          if (response.success) {
            message.success('联创返佣分配完成');
            fetchData({
              current: 1,
              pageSize: pagination.pageSize
            });
          }
        } catch (error) {
          message.error(error.message || '联创返佣分配失败');
        } finally {
          setTriggerLoading(false);
        }
      }
    });
  };

  const columns = [
    {
      title: '钱包地址',
      dataIndex: ['user', 'walletAddress'],
      render: (text) => formatWalletAddress(text),
    },
    {
      title: '返佣金额',
      dataIndex: 'amount',
      render: (value) => formatAmount(value, 2),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: '当日订单数',
      dataIndex: 'totalOrders',
      sorter: (a, b) => a.totalOrders - b.totalOrders,
    },
    {
      title: '参与分配联创数',
      dataIndex: 'coFounderCount',
    },
    {
      title: '返佣日期',
      dataIndex: 'rewardDate',
      render: (text) => formatDate(text, 'YYYY-MM-DD'),
      sorter: (a, b) => new Date(a.rewardDate) - new Date(b.rewardDate),
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (status) => {
        const statusMap = {
          pending: { text: '处理中', color: 'processing' },
          success: { text: '成功', color: 'success' },
          failed: { text: '失败', color: 'error' }
        };
        const { text, color } = statusMap[status] || {};
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: '交易哈希',
      dataIndex: 'txHash',
      render: (text) => text ? formatWalletAddress(text) : '-',
      ellipsis: true,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: (text) => formatDate(text),
    }
  ];

  return (
    <Card 
      title="联创返佣管理"
    >
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ x: 1200 }}
      />
    </Card>
  );
}

export default CoFounderRewards; 