import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Select,
  message,
  Tag,
  Spin
} from 'antd';
import { getWithdrawals, reviewWithdrawal } from '../services/withdrawal';
import { formatAmount, formatDate, formatWalletAddress } from '../utils/format';

const { Option } = Select;

export function Withdrawals() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [reviewForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  // 获取提现列表
  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const { current, pageSize, ...filters } = params;
      const res = await getWithdrawals({
        page: current,
        limit: pageSize,
        ...filters
      });

      if (res.success) {
        setData(res.withdrawals || []);
        setPagination({
          ...pagination,
          total: res.total,
          current: res.page,
          pageSize: params.pageSize || 10
        });
      }
    } catch (error) {
      message.error('获取提现列表失败');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({
      current: 1,
      pageSize: 10
    });
  }, []);

  // 处理表格变化
  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
      ...filters,
      ...sorter
    });
  };

  // 打开审核弹窗
  const handleReview = (record) => {
    setCurrentRecord(record);
    setReviewModalVisible(true);
    reviewForm.resetFields();
  };

  // 提交审核
  const handleReviewSubmit = async () => {
    try {
      const values = await reviewForm.validateFields();
      setSubmitting(true);
      const res = await reviewWithdrawal(currentRecord.id, values);

      if (res.success) {
        message.success(res.message);
        if (values.status === 'approved' && res.data.txHash) {
          Modal.success({
            title: '转账成功',
            content: (
              <div>
                <p>交易哈希：</p>
                <p style={{ wordBreak: 'break-all' }}>{res.data.txHash}</p>
              </div>
            ),
          });
        }
        setReviewModalVisible(false);
        fetchData({
          current: pagination.current,
          pageSize: pagination.pageSize
        });
      }
    } catch (error) {
      message.error(error.message || '审核失败');
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    {
      title: '用户地址',
      dataIndex: ['User', 'walletAddress'],
      render: (text) => formatWalletAddress(text),
      ellipsis: true,
    },
    {
      title: '提现地址',
      dataIndex: 'address',
      render: (text) => formatWalletAddress(text),
      ellipsis: true,
    },
    {
      title: '提现金额',
      dataIndex: 'amount',
      render: (value) => formatAmount(value, 2),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (status) => {
        const statusMap = {
          pending: { text: '待审核', color: 'gold' },
          approved: { text: '已通过', color: 'green' },
          rejected: { text: '已拒绝', color: 'red' }
        };
        const { text, color } = statusMap[status] || {};
        return <Tag color={color}>{text}</Tag>;
      },
      filters: [
        { text: '待审核', value: 'pending' },
        { text: '已通过', value: 'approved' },
        { text: '已拒绝', value: 'rejected' }
      ],
    },
    {
      title: '交易哈希',
      dataIndex: 'txHash',
      render: (text) => text ? formatWalletAddress(text) : '-',
      ellipsis: true,
    },
    {
      title: '申请时间',
      dataIndex: 'createdAt',
      render: (text) => formatDate(text),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: true,
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space>
          {record.status === 'pending' && (
            <Button type="primary" size="small" onClick={() => handleReview(record)}>
              审核
            </Button>
          )}
        </Space>
      ),
    }
  ];

  return (
    <Card title="提现管理">
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />

      <Modal
        title="审核提现申请"
        open={reviewModalVisible}
        onOk={handleReviewSubmit}
        onCancel={() => setReviewModalVisible(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Spin spinning={submitting} tip="处理中...">
          <Form
            form={reviewForm}
            layout="vertical"
          >
            <Form.Item
              name="status"
              label="审核结果"
              rules={[{ required: true, message: '请选择审核结果' }]}
            >
              <Select>
                <Option value="approved">通过</Option>
                <Option value="rejected">拒绝</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="remark"
              label="备注"
            >
              <Input.TextArea rows={4} placeholder="请输入备注说明" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </Card>
  );
}

export default Withdrawals; 