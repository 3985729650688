import React, { useState, useEffect } from 'react';
import { Card, Table, DatePicker, Input, Button } from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { commissionApi } from '../services/api';
import { formatDate, formatAmount, formatWalletAddress } from '../utils/format';

const { RangePicker } = DatePicker;

export function Commissions() {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [filters, setFilters] = useState({
    walletAddress: '',
    dateRange: null
  });

  const fetchRecords = async (params = {}) => {
    setLoading(true);
    try {
      const { current, pageSize } = pagination;
      const { walletAddress, dateRange } = filters;
      
      const query = {
        page: params.page || current,
        pageSize: params.pageSize || pageSize,
        walletAddress: walletAddress || undefined,
        startDate: dateRange?.[0]?.format('YYYY-MM-DD'),
        endDate: dateRange?.[1]?.format('YYYY-MM-DD')
      };

      const response = await commissionApi.getRecords(query);
      
      setRecords(response.data.records);
      setPagination({
        ...pagination,
        total: response.data.pagination.total,
        current: response.data.pagination.current,
        pageSize: response.data.pagination.pageSize
      });
    } catch (error) {
      console.error('获取返佣记录失败:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const handleTableChange = (newPagination) => {
    fetchRecords({
      page: newPagination.current,
      pageSize: newPagination.pageSize
    });
  };

  const handleSearch = () => {
    fetchRecords({ page: 1 });
  };

  const columns = [
    {
      title: '订单ID',
      dataIndex: ['Order', 'id'],
      width: 80,
    },
    {
      title: '获得返佣用户',
      dataIndex: ['user', 'walletAddress'],
      render: (text) => formatWalletAddress(text),
    },
    {
      title: '贡献返佣用户',
      dataIndex: ['referral', 'walletAddress'],
      render: (text) => formatWalletAddress(text),
    },
    {
      title: '返佣金额',
      dataIndex: 'amount',
      render: (value) => formatAmount(value, 2),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: '返佣比例',
      dataIndex: 'rate',
      render: (value) => `${value}%`,
    },
    {
      title: '基础金额',
      dataIndex: 'baseAmount',
      render: (value) => formatAmount(value, 2),
    },
    {
      title: '交易哈希',
      dataIndex: 'txHash',
      render: (text) => formatWalletAddress(text),
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <span className={status === 'success' ? 'text-green-600' : 'text-red-600'}>
          {status === 'success' ? '成功' : '失败'}
        </span>
      ),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: (text) => formatDate(text),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
  ];

  return (
    <Card title="返佣记录">
      <div className="mb-4 flex items-center gap-4">
        <Input
          placeholder="搜索钱包地址"
          value={filters.walletAddress}
          onChange={(e) => setFilters({ ...filters, walletAddress: e.target.value })}
          style={{ width: 200 }}
          allowClear
        />
        <RangePicker
          value={filters.dateRange}
          onChange={(dates) => setFilters({ ...filters, dateRange: dates })}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleSearch}
        >
          搜索
        </Button>
        <Button
          type="default"
          icon={<ReloadOutlined />}
          onClick={() => {
            setFilters({
              walletAddress: '',
              dateRange: null
            });
            fetchRecords();
          }}
        >
          重置
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={records}
        rowKey="id"
        pagination={pagination}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ x: 1200 }}
      />
    </Card>
  );
}