import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import { Login } from './pages/Login';
import { Orders } from './pages/Orders';
import { Users } from './pages/Users';
import { Returns } from './pages/Returns';
import { Commissions } from './pages/Commissions';
import { Withdrawals } from './pages/Withdrawals';
import { CoFounderRewards } from './pages/CoFounderRewards';
import { AdminLayout } from './components/Layout';

// 路由守卫组件
function PrivateRoute({ children }) {
  const token = localStorage.getItem('admin_token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return <AdminLayout>{children}</AdminLayout>;
}

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/returns"
            element={
              <PrivateRoute>
                <Returns />
              </PrivateRoute>
            }
          />
          <Route
            path="/commissions"
            element={
              <PrivateRoute>
                <Commissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/withdrawals"
            element={
              <PrivateRoute>
                <Withdrawals />
              </PrivateRoute>
            }
          />
          <Route
            path="/co-founder-rewards"
            element={
              <PrivateRoute>
                <CoFounderRewards />
              </PrivateRoute>
            }
          />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  );
}

export default App; 