import request from '../utils/request';

// 获取提现列表
export async function getWithdrawals(params) {
  return request('/admin/withdrawals', {
    method: 'GET',
    params
  });
}

// 获取提现详情
export async function getWithdrawalDetail(id) {
  return request(`/admin/withdrawals/${id}`, {
    method: 'GET'
  });
}

// 审核提现申请
export async function reviewWithdrawal(id, data) {
  return request(`/admin/withdrawals/${id}/review`, {
    method: 'PUT',
    data
  });
} 