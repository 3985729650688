import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Space, Modal, message, Tag, Progress, Tooltip } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { orderApi } from '../services/api';
import { formatDate, formatAmount, formatWalletAddress } from '../utils/format';

const { confirm } = Modal;

// 订单状态标签颜色映射
const statusColors = {
  unpaid: 'default',
  pending: 'processing',
  completed: 'success',
  failed: 'error'
};

// 订单状态中文映射
const statusText = {
  unpaid: '待支付',
  pending: '处理中',
  completed: '已完成',
  failed: '失败'
};

// 返还状态标签颜色映射
const returnStatusColors = {
  pending: 'default',
  in_progress: 'processing',
  completed: 'success'
};

// 返还状态中文映射
const returnStatusText = {
  pending: '待开始',
  in_progress: '进行中',
  completed: '已完成'
};

export function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const fetchOrders = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const response = await orderApi.getOrders({
        page,
        pageSize
      });

      if (response.success) {
        setOrders(response.data.orders);
        setPagination({
          ...pagination,
          current: response.data.pagination.current,
          total: response.data.pagination.total
        });
      }
    } catch (error) {
      console.error('获取订单列表失败:', error);
      message.error('获取订单列表失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleTableChange = (pagination) => {
    fetchOrders(pagination.current, pagination.pageSize);
  };

  const handleDelete = (orderId) => {
    confirm({
      title: '确认删除',
      icon: <ExclamationCircleOutlined />,
      content: '确定要删除这个订单吗？此操作不可恢复。',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        try {
          const response = await orderApi.deleteOrder(orderId);
          if (response.success) {
            message.success('删除订单成功');
            fetchOrders(pagination.current, pagination.pageSize);
          }
        } catch (error) {
          console.error('删除订单失败:', error);
          message.error(error.message || '删除订单失败');
        }
      }
    });
  };

  const columns = [
    {
      title: '订单ID',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: '钱包地址',
      dataIndex: 'walletAddress',
      render: (text) => (
        <a href={`https://bscscan.com/address/${text}`} target="_blank" rel="noopener noreferrer">
          {formatWalletAddress(text)}
        </a>
      ),
    },
    {
      title: '支付金额(USDT)',
      dataIndex: 'amount',
      render: (value) => formatAmount(value),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: '代币数量',
      dataIndex: 'tokenAmount',
      render: (value) => formatAmount(value, 0),
      sorter: (a, b) => a.tokenAmount - b.tokenAmount,
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <Tag color={statusColors[status]}>
          {statusText[status]}
        </Tag>
      ),
    },
    {
      title: '返还状态',
      dataIndex: 'returnStatus',
      width: 120,
      render: (status, record) => (
        <Space direction="vertical" size="small">
          <Tag color={returnStatusColors[status]}>
            {returnStatusText[status]}
          </Tag>
          {status === 'in_progress' && (
            <Tooltip title={`已返还: ${formatAmount(record.returnedAmount, 0)} / ${formatAmount(record.tokenAmount, 0)}`}>
              <Progress
                percent={Math.floor((record.returnedAmount / record.tokenAmount) * 100)}
                size="small"
                status="active"
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
    {
      title: '已返还数量',
      dataIndex: 'returnedAmount',
      render: (value) => formatAmount(value, 0),
      sorter: (a, b) => a.returnedAmount - b.returnedAmount,
    },
    {
      title: '剩余数量',
      dataIndex: 'remainingAmount',
      render: (value) => formatAmount(value, 0),
      sorter: (a, b) => a.remainingAmount - b.remainingAmount,
    },
    {
      title: '下次返还时间',
      dataIndex: 'nextReturnTime',
      render: (text) => text ? formatDate(text) : '-',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: (text) => formatDate(text),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (_, record) => (
        <Space>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            disabled={record.status !== 'unpaid'} // 只允许删除待支付的订单
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card title="订单管理">
      <Table
        columns={columns}
        dataSource={orders}
        rowKey="id"
        pagination={pagination}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ x: 1500 }}
      />
    </Card>
  );
}