import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  OrderedListOutlined,
  UserOutlined,
  RetweetOutlined,
  GiftOutlined,
  LogoutOutlined,
  WalletOutlined,
  CrownOutlined
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const menuItems = [
  {
    key: '/orders',
    icon: <OrderedListOutlined />,
    label: <Link to="/orders">订单管理</Link>
  },
  {
    key: '/users',
    icon: <UserOutlined />,
    label: <Link to="/users">用户管理</Link>
  },
  {
    key: '/returns',
    icon: <RetweetOutlined />,
    label: <Link to="/returns">返还记录</Link>
  },
  {
    key: '/commissions',
    icon: <GiftOutlined />,
    label: <Link to="/commissions">返佣记录</Link>
  },
  {
    key: '/withdrawals',
    icon: <WalletOutlined />,
    label: <Link to="/withdrawals">提现管理</Link>
  },
  {
    key: '/co-founder-rewards',
    icon: <CrownOutlined />,
    label: <Link to="/co-founder-rewards">联创返佣</Link>
  }
];

export function AdminLayout({ children }) {
  const location = useLocation();
  const selectedKey = menuItems.find(item => location.pathname.startsWith(item.key))?.key || '/orders';

  const handleLogout = () => {
    localStorage.removeItem('admin_token');
    window.location.href = '/login';
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="flex items-center justify-between px-6 bg-white border-b">
        <h1 className="text-lg font-bold">Shriley 后台管理</h1>
        <button
          onClick={handleLogout}
          className="flex items-center text-gray-600 hover:text-gray-900"
        >
          <LogoutOutlined className="mr-1" />
          退出登录
        </button>
      </Header>
      <Layout>
        <Sider width={200} className="bg-white">
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Sider>
        <Layout style={{ padding: '24px' }}>
          <Content className="bg-white p-6 rounded-lg">
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
} 